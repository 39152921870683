<script>
import Layout from '@layouts/main'

import { setComputed } from '@state/helpers'

import MenuCarousel from "@components/MenuCarousel";

export default {
  components: { Layout, MenuCarousel},
  props: {
    menuModule: {
      type: Array,
      required: true,
    }
  },
  data() {
    return {
      animateStart: false,
      // window_w: 0,
      text: {
        'zh-TW': {
          slogan: [
            "<span>饗</span><span>．</span><span>樂</span>", "產地精選鮮採時蔬、日本手作鍋物配料，<br>將大自然的豐饒完美盛裝"
          ]
        },
        'en': {
          slogan: [
            "<span>Enjoy</span><span> the </span><span>Feast</span>", "Fresh seasonal vegetables and Japanese handmade selections<br>Serving Mother Nature’s abundance perfectly for you"
          ]
        }
      }
    }
  },
  computed: {
    ...setComputed,
    textModule() {
      return this.currentLanguage === 'zh-TW'?  this.text['zh-TW']:this.text['en']
    }
    /* bannerTranformStyle() {
      const originWidth = 1920;
      const windthWidth = this.window_w;

      if(this.window_w > 1920) {
        let scale = 1 / (originWidth / windthWidth);

        return {
          transform: `scale(${scale })`,
          '--webkit--transform': `scale(${scale })`,
          marginBottom: `${scale * 300 }px`,
        }
      }else {
        return {}
      }
    }, */
  },
  mounted() {
    setTimeout(()=> {
      this.animateStart = true
    }, 600)

    // this.getWindowWidth();
    // window.addEventListener('resize', this.getWindowWidth)
  },
  destroyed() {
    // window.removeEventListener('resize', this.getWindowWidth)
  },
  methods: {
    /* getWindowWidth(){
      this.window_w = document.body.clientWidth
    }, */
  }
}
</script>

<template>
  <Layout>
    <section :class="[$style.sectionBanner, { [$style.isAnimateStart]: animateStart }]">
      <h2 :class="[$style.title, { [$style['write-horizontal']]: this.currentLanguage !== 'zh-TW' }]" v-html="textModule.slogan[0]"></h2>
      <p :class="$style.content" v-html="textModule.slogan[1]"></p>
    </section>
    <section :class="$style.sectionMenu">
      <MenuCarousel :list="menuModule"/>
    </section>
  </Layout>
</template>
<style lang="scss" module>
  $banner-height: 782px;
  .sectionBanner {
    background-image: url('@assets/images/menu/menu_banner.jpg');
    background-position: center;
    color: #ece8de;
    background-repeat: no-repeat;
    position: relative;
    z-index: 1;
    height: $banner-height;
    transform-origin: top;
    @include touch {
      height: 620px;
      background-position: top;
    }
    &:before {
      content: "";
      height: 200px;
      width: 100%;
      position: absolute;
      left: 0;
      transform: translateY(-50%);
      background-image: linear-gradient(to top, rgba(0, 0, 0, 0), #000000);
      z-index: -1;
      // top: calc((100vh - 610px) / 2);
      top: 150px;
      @include touch {
        top: 0%;
        transform: translateY(0);
      }
    }
    &:after {
      @media screen and (min-width: 1920px) {
        content: "";
        height: 782px;
        width: 1960px;
        position: absolute;
        left: 0;
        z-index: -1;
        top: 50%;
        left: 50%;
        margin-left: -980px;
        margin-top: -391px;
        box-shadow: 0 0px 60px 60px #000 inset;
      }
    }
    .title {
      writing-mode: vertical-lr;
      font-size: 48px;
      letter-spacing: 10px;
      position: absolute;
      left: 50%;
      transform: translate(-50%, -50%);
      //top: calc((100vh - 130px) / 2);
      top: calc(#{$banner-height} / 2);
      margin: 0;
      margin-top: -181px;
      @include touch {
        top: 75px;
        margin-top: 0;
        transform: translateX(-50%);
      }
    }
    .content {
      font-size: 16px;
      line-height: 28px;
      letter-spacing: 1px;
      position: absolute;
      left: 50%;
      transform: translate(-50%, -50%);
      // top: calc((100vh - 130px) / 2);
      top: calc(#{$banner-height} / 2);
      margin: 0;
      margin-top: 20px;
      text-align: center;
      font-weight: 500;
      @include touch {
        top: 345px;
        margin-top: 0;
        transform: translateX(-50%);
        width: 100%;
      }
    }
    &.isAnimateStart {
      .title {
        span {
          opacity: 1;
        }
      }
      .content {
        opacity: 1;
      }
    }
    .title {
      &.write-horizontal {
        writing-mode: horizontal-tb !important;
      }
      span {
        opacity: 0;
        &:nth-child(1) {
          transition: opacity 2.1s linear 0.3s;
        }
        &:nth-child(2) {
          transition: opacity 2.1s linear 0.6s;
        }
        &:nth-child(3) {
          transition: opacity 2.1s linear 0.9s;
        }
      }
    }
    .content {
      transition: opacity 2.1s linear 1.8s;
      opacity: 0;
    }
  }
  .sectionMenu {
    padding: 0 0 110px;
    background-image: url('@assets/images/menu/menu_bg.jpg');
    background-size: cover;
    background-position: center;
    position: relative;
  }
</style>
