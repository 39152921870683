<script>
// https://github.com/surmon-china/vue-awesome-swiper
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'

import { setComputed } from '@state/helpers'

export default {
  components: { swiper, swiperSlide },
  props: {
    list: {
      type: Array,
      required: true
    }
  },
  computed: {
    prevIsDisabled() {
      if(this.window_w > 768) {
        if(this.pageCount > 1) {
          return false;
        }
      }else {
        if(this.pageCount > 0) {
          return false;
        }
      }

      return true
    },
    nextIsDisabled() {
      if(this.window_w > 768) {
        if(this.pageCount < this.carouselList.length - 2) {
          return false;
        }
      }else {
        if(this.pageCount < this.carouselList.length - 1) {
          return false;
        }
      }

      return true
    },
    // Desktop
    carouselList() {
      const carouses = [];

      this.list.forEach(item => {
        if(this.getExtrafields(item, 'menu_image1')){
          carouses.push({
            title: this.getExtrafields(item, 'menu_title1'),
            image: `${this.currentWebsite.main}${this.getExtrafields(item, 'menu_image1')}`
          });
        }else{
          if(this.window_w > 768) {
            carouses.push({
              title: '',
              image: ''
            });
          }
        }

        if(this.getExtrafields(item, 'menu_image2')){
          carouses.push({
            title: this.getExtrafields(item, 'menu_title2'),
            image: `${this.currentWebsite.main}${this.getExtrafields(item, 'menu_image2')}`
          });
        }else{
          if(this.window_w > 768) {
            carouses.push({
              title: '',
              image: ''
            });
          }
        }
      });

      return carouses;
    },
    selectList() {
      const carouses = [];

      this.carouselList.forEach((item, $index) => {
        const title = item.title;
        const preTitle = $index > 0 ? this.carouselList[$index - 1].title : '';

        if(title !== preTitle) {
          carouses.push({
            title: title,
            value: $index
          });
        }
      });

      /* this.list.forEach((item, $index) => {
        const title1 = this.getExtrafields(item, 'menu_title1');
        const title2 = this.getExtrafields(item, 'menu_title2');

        // 標題一樣
        if(title1 === title2) {
          carouses.push({
            title: title1,
            value: $index * 2
          });
        }

        // 標題不一樣
        if(title1 !== title2) {
          if(title1 !== '') {
            carouses.push({
              title: title1,
              value: $index * 2
            });
          }

          if(title2 !== '') {
            carouses.push({
              title: title2,
              value: ($index * 2) + 1
            });
          }
        }
      }); */

      return carouses;
    },
    ...setComputed
  },
  data() {
    const $self = this;
    return {
      window_w: 1920,
      pageCount: 0,
      selectIndex: 0,
      swiperOption: {
        slidesPerView: 2,
        speed: 600,
        slidesPerGroup : 2,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        breakpoints: {
          768: {
            slidesPerView: 1,
            slidesPerGroup : 1,
          },
        },
        on: {
          slideChange () {
            $self.pageCount = ( this.activeIndex % $self.carouselList.length ) || 0

            const sameValue = $self.selectList.filter(item => {
              return item.value === $self.pageCount
            })

            if(sameValue.length > 0) {
              $self.selectIndex = $self.pageCount
            }
          }
        },
      },
    }
  },
  mounted() {
    this.getWindowWidth();
    window.addEventListener('resize', this.getWindowWidth)
  },
  destroyed() {
    window.removeEventListener('resize', this.getWindowWidth)
  },
  methods: {
    setSlide(index) {
      this.$refs.menuSwiper.swiper.slideTo(index, 600)
    },
    onNext() {
      if(this.window_w > 768) {
        if(this.pageCount < this.carouselList.length - 1) {
          this.setSlide(this.pageCount + 2);
        }
      }else {
        if(this.pageCount < this.carouselList.length) {
          this.setSlide(this.pageCount + 1);
        }
      }

    },
    onPrev() {
      if(this.window_w > 768) {
        if(this.pageCount > 1) {
          this.setSlide(this.pageCount - 2);
        }
      }else {
        if(this.pageCount > 0) {
          this.setSlide(this.pageCount - 1);
        }
      }

    },
    getExtrafields(item, field) {
      if(field in item.extrafields){
        return item.extrafields[field].value;
      }else{
        return ''
      }
    },
    menuTitleArray(item) {
      const title1 = this.getExtrafields(item, 'menu_title1');
      const title2 = this.getExtrafields(item, 'menu_title2');
      let title = title1;

      if(this.getExtrafields(item, 'menu_title2') && title1 !== title2) {
        title += `,${title2}`
      }

      return title ? title.split(',') : [];
    },
    menuTitle(title, index) {
      return title
      /* if(index > 0) {
        if(title === this.list[index - 1].title) {
          return ' '
        }else {
          return title
        }
      }else {
        return title
      } */
    },
    changeMenuCarousel(value) {
      this.setSlide(value);
    },
    selectIsSelect(value, index) {
      if(value === this.pageCount) {
        return true
      }

      if(index < this.selectList.length - 1) {


        if(this.pageCount < this.selectList[index + 1].value && this.pageCount >= value){
          return true
        }
      }

    },
    getWindowWidth(){
      this.window_w = document.body.clientWidth
    }
  },

};
</script>
<template>
  <div :class="$style.carouselBox">
    <div class="d-none d-lg-flex" :class="$style.menuBox">
      <div :class="$style.menuList">
        <div
          v-for="(menuImage, $index) in list"
          :key="$index"
          @click="setSlide((($index + 1) * 2) - 2)"
          :class="[$style.menuItem, { [$style.isActive]: pageCount === ((($index + 1) * 2) - 2) }]">
          <div :class="$style.title"
               v-for="(title, $index2) in menuTitleArray(menuImage)" :key="$index2">
            {{ menuTitle(title, $index) }}
          </div>
        </div>
      </div>
      <div :class="$style.toggle">
        {{$t('global.MENU')}}
      </div>
    </div>
    <div class="d-block d-lg-none" :class="$style.selectBox">
      <el-select
        v-model="selectIndex"
        @change="changeMenuCarousel"
        placeholder="月份"
        class="menuCarousel"
        popper-class="menuCarousel"
      >
        <el-option
          v-for="(select, $index) in selectList"
          :key="$index"
          :label="select.title"
          :class="{ isSelect: selectIsSelect(select.value, $index) }"
          :value="select.value">
        </el-option>
      </el-select>
    </div>
    <div class="container-menu position-relative">
      <swiper
        :class="$style.carousel"
        :options="swiperOption"
        ref="menuSwiper"
      >
        <swiper-slide
          v-for="(menuImage, $index) in carouselList"
          :key="`${$index}carouselSlide`"
          :class="[$style.carouselSlide]"
        >
          <img :src="menuImage.image">
        </swiper-slide>
      </swiper>
      <div :class="$style.navigationBox">
        <button @click="onPrev" :class="[$style.navigationPrev, $style.navigationBtn,
        { [$style.isDisabled]: prevIsDisabled }]">
          <i :class="$style.arrowIcon"></i>
        </button>
        <button @click="onNext" :class="[$style.navigationNext, $style.navigationBtn,
        { [$style.isDisabled]: nextIsDisabled }]">
          <i :class="$style.arrowIcon"></i>
        </button>
      </div>
    </div>
  </div>
</template>
<style lang="scss" module>
  $block-height: 900px;
    // data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNyA0NCI+PHBhdGggZD0iTTAsMjJMMjIsMGwyLjEsMi4xTDQuMiwyMmwxOS45LDE5LjlMMjIsNDRMMCwyMkwwLDIyTDAsMjJ6IiBmaWxsPSIjZmZmIi8+PC9zdmc+
  .carouselBox {
    height: $block-height;
    position: relative;
    @include touch {
      height: auto;
    }
    .carousel {
      // margin-left: -1.5rem;
      // margin-right: -1.5rem;
      .carouselSlide {
        // padding: 0 1rem;
        img {
          max-width: 100%;
        }
      }
    }
  }
  .selectBox {
    padding: 0 20px;
    margin-bottom: 40px;
  }
  .menuBox {
    background: #faf9f5;
    height: $block-height;
    padding: 5px;
    width: 214px;
    padding-left: 2.5rem;
    display: flex;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    z-index: 2;
    margin-left: -183px;
    transition: margin 0.3s;
    &:hover {
      margin-left: 0;
      .toggle {
        width: 0;
      }
    }
    .menuList {
      .menuItem {
        font-size: 16px;
        line-height: 18px;
        letter-spacing: 2px;
        font-weight: 500;
        margin: 2rem 0;
        color: #000;
        padding-left: 1rem;
        position: relative;
        cursor: pointer;
        min-height: 1em;
        .title:not(:last-child) {
          margin-bottom: 1.5rem;
        }
        &:before {
          content: "";
          width: 6px;
          height: 6px;
          background: #c7c6c0;
          position: absolute;
          left: 0;
          top: 9px;
          margin-top: -3px;
          border-radius: 100%;
        }
        &:hover:not(.isActive) {
          &:before {
            background: mix(#e85400, #c7c6c0, 50%);
          }
        }
        &:active {
          color: #333;
        }
        &.isActive {
          &:before {
            background: #e85400;
          }
        }
      }
    }
  }
  .toggle {
    color: #fff;
    background: #3b3b3b;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    writing-mode: vertical-lr;
    text-align: center;
    line-height: 2em;
    letter-spacing: 0.7em;
    overflow: hidden;
    width: 2rem;
    transition: width 0.3s;
  }
  .navigationBox {
    position: absolute;
    width: 100%;
    top: 50%;
    margin-bottom: 30px;
    z-index: 1;
    left: 0;
    @include touch {
      width: 100vw
    }
    .navigationBtn {
      width: 100px;
      height: 100px;
      position: absolute;
      top: 50%;
      color: #f5f1ed;
      appearance: none;
      background: transparent;
      border: 0px solid transparent;
      margin-top: -50px;
      text-align: center;
      @include touch {
        font-size: 1.5rem;
      }
      &:focus {
        outline: none;
      }
      &:active {
        opacity: 0.5;
      }
      &.isDisabled {
        opacity: 0.3;
      }
      .arrowIcon {
        &:before {
          content: '';
          background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNyA0NCI+PHBhdGggZD0iTTAsMjJMMjIsMGwyLjEsMi4xTDQuMiwyMmwxOS45LDE5LjlMMjIsNDRMMCwyMkwwLDIyTDAsMjJ6IiBmaWxsPSIjZmZmIi8+PC9zdmc+);
          width: 15px;
          height: 30px;
          display: block;
          margin: auto;
          background-position: center;
          background-repeat: no-repeat;
          opacity: 0.6;
        }
      }
    }
    .navigationPrev {
      left: -100px;
      @include touch {
        left: -1rem;
      }
    }
    .navigationNext {
      right: -100px;
      @include touch {
        right: -1rem;
      }
      .arrowIcon {
        &:before {
          transform: scaleX(-1);
        }
      }
    }
  }
</style>
